/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'
import Secumail from './MiscSecumail'

// Components
interface CustomMiscProps {
  fields: any
  location?: any
}

interface CustomMiscsProps {
  [key: string]: any
}

const MiscShell: React.FC<CustomMiscProps> = ({ fields, location = {} }) => {
  const customMiscs: CustomMiscsProps = {
    secumail: Secumail,
  }

  if (!fields.styletype || !customMiscs[fields.styletype]) {
    return null
  }

  const Component = customMiscs[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : ''}`}
      fields={fields}
      location={location}
    />
  )
}

export default MiscShell
